// @refresh reset
import tailwind from '../../tailwind.config'
import { fileToDataUrl } from '@/utils/dom'
import {
  filterEvents,
  makeDefaultObservable,
  uiEvents,
  useDefaultObservable,
  useSubject,
  useSubscription,
} from '@/utils/event'
import { useEffect, useRef } from 'react'
import { combineLatest, firstValueFrom, merge, Observable, of } from 'rxjs'
import { filter, map, mapTo, share, switchMap, take, tap } from 'rxjs/operators'
import { Overlay } from './overlay'
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { BottomButton } from './bottom-button'
import { useAppContext } from '@/pages/_app'
import { CropBox, Rectangle } from 'shared/types/api'
import ReactSlider from 'react-slider'
import { useObservable } from 'rxjs-hooks'
import { useVenueContext } from '@/services/venue'

declare global {
  interface AppUIEventMap {
    CropperImageSubmit: {
      id: string
      type: 'CropperImageSubmit'
      file: File
      aspectRatio: number
    }
    CropperImageReady: {
      id: string
      type: 'CropperImageReady'
      file: File
      cropBox: CropBox
    }
  }
}

const CROPPER_OVERLAY_ID = 'cropper'
const MAX_ZOOM = 3

export default function CropperOverlay() {
  type OverlayState =
    | {
        type: 'show'
        fileDataUrl: string
        file: File
        cropperId: string
        aspectRatio: number
      }
    | {
        type: 'hide'
      }

  const filesToUpload$ = uiEvents.pipe(
    filterEvents(['CropperImageSubmit']),
    switchMap(async (event) => {
      uiEvents.next({ type: 'LayoutForceMobileView', state: true })
      await firstValueFrom(uiEvents.pipe(filterEvents(['LayoutResize']), take(1), mapTo(event)))

      const Compressor = (await import('compressorjs')).default
      const blob = await new Promise<Blob>((resolve, reject) => {
        new Compressor(event.file, {
          success: resolve,
          error: reject,
          quality: 0.8,
          maxWidth: 2000,
        })
      })
      const compressedFile = new File([blob], event.file.name, {
        type: event.file.type,
      })
      const fileDataUrl = await fileToDataUrl(compressedFile)
      return {
        type: 'show',
        file: compressedFile,
        fileDataUrl,
        cropperId: event.id,
        aspectRatio: event.aspectRatio,
      } as const
    }),
  )

  const backClicks$ = uiEvents.pipe(
    filterEvents(['OverlayBackClick']),
    filter((event) => {
      return event.overlayId === CROPPER_OVERLAY_ID
    }),
    mapTo({
      type: 'hide',
    } as const),
  )

  const cropperImageReady$ = uiEvents.pipe(
    filterEvents(['CropperImageReady']),
    mapTo({
      type: 'hide',
    } as const),
  )

  const overlayState$ = merge(filesToUpload$, backClicks$, cropperImageReady$)
  useSubscription(merge(backClicks$, cropperImageReady$), () => {
    uiEvents.next({ type: 'LayoutForceMobileView', state: false })
  })

  const overlayState = useDefaultObservable(
    makeDefaultObservable<OverlayState>({ type: 'hide' }, overlayState$),
  )

  if (overlayState.type === 'hide') {
    return null
  }

  return <CropperUi {...overlayState} />
}

type CropperUiProps = {
  aspectRatio: number
  fileDataUrl: string
  file: File
  cropperId: string
}
function CropperUi(props: CropperUiProps) {
  const { t } = useAppContext()
  const rootRef = useRef<HTMLDivElement>(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const cropperRef = useRef<Cropper | null>(null)
  useEffect(() => {
    return () => {
      if (cropperRef.current) {
        cropperRef.current.destroy()
      }
    }
  }, [])

  const zoomToCenter = () => {
    const cropper = cropperRef.current!
    const imageData = cropper.getImageData()
    const cropperWidth = rootRef.current!.offsetWidth
    const cropperHeight = rootRef.current!.offsetHeight
    const desiredZoomRatioMultiplier =
      cropperWidth > imageData.width
        ? cropperWidth / imageData.width
        : cropperHeight / imageData.height

    const currentZoomRatio = imageData.height / imageData.naturalHeight

    cropper.zoomTo(currentZoomRatio * desiredZoomRatioMultiplier)
  }

  const cropperReadyEvents$ = useSubject<Event>()
  const initialCropperState$ = cropperReadyEvents$.pipe(
    map(() => {
      const cropper = cropperRef.current!
      const canvasData = cropper.getCanvasData()

      const imageData = cropper.getImageData()
      const zoomRatio = imageData.height / imageData.naturalHeight

      zoomToCenter()
      return { canvasData, zoomRatio }
    }),
    tap(() => {
      const cropper = cropperRef.current!

      // resize the crop box to the whole container
      cropper.setCropBoxData({ left: 0, top: 0, ...cropper.getContainerData() })
    }),
    share(),
  )

  const initialCropperState = useObservable(() => {
    return initialCropperState$
  })

  type ZoomEvent = Event & {
    detail: { ratio: number; oldRatio: number }
  }
  const zoomEvents$ = useSubject<ZoomEvent>()

  const currentZoomRatio = useObservable(() => {
    return combineLatest([initialCropperState$, zoomEvents$]).pipe(
      map(([initialCropperState, zoomEvent]) => {
        const currentZoomRatio = zoomEvent.detail.ratio

        const cropper = cropperRef.current!
        if (currentZoomRatio < initialCropperState.zoomRatio) {
          zoomEvent.preventDefault()
          cropper.zoomTo(initialCropperState.zoomRatio)
        }

        if (currentZoomRatio > MAX_ZOOM) {
          zoomEvent.preventDefault()
          cropper.zoomTo(MAX_ZOOM)
        }

        return currentZoomRatio
      }),
    )
  })

  const logSliderTransformer = initialCropperState
    ? makeLogSliderTransformer(initialCropperState.zoomRatio, MAX_ZOOM)
    : null

  return (
    <Overlay id={CROPPER_OVERLAY_ID} title={t('cropper:uploadImage')}>
      <div
        className="px-4 my-4"
        style={{
          visibility: initialCropperState ? 'visible' : 'hidden',
        }}
      >
        <style>{`
          body {
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
          }
          .cropper-bg {
            background-image: none !important;
            background-color: white;
          }  
          .cropper-view-box {
            outline: none;
            border: 1px solid ${tailwind.theme.colors.opaqueLight};
            border-radius: 0.5rem;
          }
          .cropper-wrap-box {
            position: relative !important;
          }
        `}</style>
        <div ref={rootRef} className="h-full">
          <img
            className="hidden"
            src={props.fileDataUrl}
            ref={imageRef}
            onLoad={async () => {
              const cropperWidth = rootRef.current!.offsetWidth
              // eslint-disable-next-line immutable/no-mutation
              rootRef.current!.style.height = `${Math.round(
                cropperWidth / props.aspectRatio,
              ).toString()}px`
              // https://github.com/fengyuanchen/cropperjs#options
              // eslint-disable-next-line immutable/no-mutation
              cropperRef.current = new Cropper(imageRef.current!, {
                viewMode: 0,
                dragMode: 'move',
                aspectRatio: props.aspectRatio,
                guides: false,
                modal: false,
                center: false,
                autoCropArea: 1,
                cropBoxMovable: false,
                cropBoxResizable: false,
                toggleDragModeOnDblclick: false,
              })

              imageRef.current?.addEventListener('ready', (event) => {
                cropperReadyEvents$.next(event)
              })

              imageRef.current?.addEventListener('zoom', (event) => {
                zoomEvents$.next(event as unknown as ZoomEvent)
              })
            }}
          />
          <div className="relative h-0 w-full flex justify-between px-3" style={{ top: '-3rem' }}>
            <div
              onClick={() => {
                const cropper = cropperRef.current!
                const currentCanvasData = cropper.getCanvasData()

                const calculateZoomRatio = (canvasData: Cropper.CanvasData) => {
                  return canvasData.height / canvasData.naturalHeight
                }

                const currentZoomRatio = calculateZoomRatio(currentCanvasData)
                const initialZoomRatio = calculateZoomRatio(initialCropperState!.canvasData)

                cropper.zoomTo(initialZoomRatio)
                cropper.moveTo(
                  initialCropperState!.canvasData.left,
                  initialCropperState!.canvasData.top,
                )

                if (currentZoomRatio === initialZoomRatio) {
                  zoomToCenter()
                }
              }}
              className="flex items-center justify-center bg-back dark:bg-backDark border border-accent rounded-lg cursor-pointer select-none"
              style={{
                width: '40px',
                height: '35px',
              }}
            >
              <RezoomIcon />
            </div>
            {/* <div
              onClick={() => {
                // make a linear animation for rotation with the duration of 100ms
                // and the resulting turn of -90deg (30 * -3)
                const turnBy = -90
                const animationDurationMs = 100
                const frames = 30
                interval(animationDurationMs / frames)
                  .pipe(take(frames))
                  .subscribe(() => {
                    cropperRef.current!.rotate(turnBy / frames)
                  })
              }}
              className="flex items-center justify-center bg-back dark:bg-backDark border border-accent rounded-lg cursor-pointer select-none"
              style={{
                width: '40px',
                height: '35px',
              }}
            >
              <RotateIcon />
            </div> */}
          </div>
          {logSliderTransformer && currentZoomRatio && (
            <div className="mt-7 flex items-center">
              <style jsx>{`
                .animated {
                  transition: none 0.1s linear;
                  transition-property: left, right;
                }
              `}</style>
              <ZoomOutIcon />
              <ReactSlider
                className="mx-3 w-full h-7"
                value={logSliderTransformer.position(currentZoomRatio)}
                min={0}
                max={100}
                step={1}
                onChange={(value) => {
                  return cropperRef.current!.zoomTo(logSliderTransformer.value(value))
                }}
                renderThumb={(props) => {
                  return (
                    <div
                      {...props}
                      className={`${
                        props.className ?? ''
                      } h-7 w-7 rounded-full border-2 border-opaque bg-back dark:bg-backDark focus:outline-none shadow-md animated`}
                    ></div>
                  )
                }}
                renderTrack={(props, state) => {
                  const line =
                    state.index === 0 ? (
                      <div className="bg-action dark:bg-actionDark rounded-full h-0.5 w-full"></div>
                    ) : (
                      <div
                        style={{ height: '1px' }}
                        className="bg-opaqueLight rounded-full w-full"
                      ></div>
                    )

                  return (
                    <div
                      {...props}
                      className={`${props.className ?? ''} flex items-center h-full px-2 animated`}
                    >
                      {line}
                    </div>
                  )
                }}
              />
              <ZoomInIcon />
            </div>
          )}
          <BottomButton>
            <div
              onClick={() => {
                const cropper = cropperRef.current!
                const cropData = cropper.getData()
                const imageData = cropper.getImageData()

                const originalWidth = imageData.naturalWidth
                const originalHeight = imageData.naturalHeight
                const rotation = imageData.rotate ?? 0
                const [rotatedWidth, rotatedHeight] = [90, 270].includes(Math.abs(rotation))
                  ? [originalHeight, originalWidth]
                  : [originalWidth, originalHeight]
                const rect1 = {
                  x: -cropData.x,
                  y: -cropData.y,
                  width: rotatedWidth,
                  height: rotatedHeight,
                }
                const rect2 = {
                  x: 0,
                  y: 0,
                  width: cropData.width,
                  height: cropData.height,
                }
                const overlap = getOverlap(rect1, rect2)
                if (!overlap) {
                  return
                }
                const overlapAdjusted = {
                  ...overlap,
                  x: overlap.x - rect1.x,
                  y: overlap.y - rect1.y,
                }

                uiEvents.next({
                  type: 'CropperImageReady',
                  id: props.cropperId,
                  file: props.file,
                  cropBox: {
                    area: overlapAdjusted,
                    rotate: cropData.rotate,
                  },
                })
              }}
              className="flex items-center h-12 text-lg justify-center rounded-lg bg-back dark:bg-backAccentDark dark:text-primaryAccentDark font-medium cursor-pointer"
            >
              <span>{t('done')}</span>
            </div>
          </BottomButton>
        </div>
      </div>
    </Overlay>
  )
}

export type CropperProps = {
  cropperId: string
  aspectRatio: number
  input$: Observable<File>
  uploadImage?: (event: AppUIEventMap['CropperImageReady']) => Promise<void>
}

export function useCropper(props: CropperProps) {
  const venueContext = useVenueContext()!

  useSubscription(props.input$, async (file) => {
    uiEvents.next({
      id: props.cropperId,
      aspectRatio: props.aspectRatio,
      type: 'CropperImageSubmit',
      file,
    })
  })

  const imageReady$ = uiEvents.pipe(
    filterEvents(['CropperImageReady']),
    filter((event) => {
      return event.id === props.cropperId
    }),
  )

  const imageUploaded$ = props.uploadImage
    ? imageReady$.pipe(
        switchMap(async (event) => {
          await props.uploadImage!(event)
          venueContext.refreshVenue()
        }),
        switchMap(() => {
          return venueContext.venueUpdated$
        }),
        share(),
      )
    : of()

  const isImageUploading = useObservable(() => {
    return merge(imageReady$.pipe(mapTo(true)), imageUploaded$.pipe(mapTo(false)))
  }, false)

  const imgHasBeenUploaded = useObservable(() => {
    return imageUploaded$.pipe(mapTo(true))
  }, false)

  return {
    imageReady$,
    imageUploaded$,
    isImageUploading,
    imgHasBeenUploaded,
  }
}

function makeLogSliderTransformer(from: number, to: number) {
  const minValue = Math.log(from)
  const maxValue = Math.log(to)
  const scale = (maxValue - minValue) / 100
  return {
    value(position: number) {
      return Math.exp(position * scale + minValue)
    },
    position(value: number) {
      return (Math.log(value) - minValue) / scale
    },
  }
}

function getOverlap(rectangle1: Rectangle, rectangle2: Rectangle): null | Rectangle {
  const intersectionX1 = Math.max(rectangle1.x, rectangle2.x)
  const intersectionX2 = Math.min(rectangle1.x + rectangle1.width, rectangle2.x + rectangle2.width)
  if (intersectionX2 < intersectionX1) {
    return null
  }

  const intersectionY1 = Math.max(rectangle1.y, rectangle2.y)
  const intersectionY2 = Math.min(
    rectangle1.y + rectangle1.height,
    rectangle2.y + rectangle2.height,
  )
  if (intersectionY2 < intersectionY1) {
    return null
  }

  return {
    x: intersectionX1,
    y: intersectionY1,
    width: intersectionX2 - intersectionX1,
    height: intersectionY2 - intersectionY1,
  }
}

function RezoomIcon() {
  return (
    <svg
      width="28"
      height="30"
      fill="none"
      viewBox="0 0 28 30"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.4224 13.1985L22.4233 5.5476L14.7725 5.54666"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.39476 16.5037L5.39476 24.1545L13.0456 24.1545"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

// function RotateIcon() {
//   return (
//     <svg width="20" height="19" viewBox="0 0 20 19" className="stroke-primary dark:stroke-primaryDark" xmlns="http://www.w3.org/2000/svg">
//       <path
//         d="M1 1.89166V7.29204H6.40038"
//
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//       <path
//         d="M3.25916 11.7924C3.84276 13.4488 4.94888 14.8707 6.41087 15.8439C7.87287 16.817 9.61152 17.2887 11.3649 17.1878C13.1182 17.0869 14.7913 16.4188 16.132 15.2844C17.4727 14.1499 18.4084 12.6105 18.7981 10.898C19.1878 9.18556 19.0104 7.39282 18.2926 5.78993C17.5748 4.18705 16.3556 2.86085 14.8185 2.01116C13.2815 1.16148 11.51 0.834329 9.77083 1.07901C8.0317 1.3237 6.4192 2.12696 5.1763 3.36777L1 7.29205"
//
//         strokeWidth="2"
//         strokeLinecap="round"
//         strokeLinejoin="round"
//       />
//     </svg>
//   )
// }

function ZoomOutIcon() {
  return (
    <svg
      fill="none"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0004 19L14.6504 14.65"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M6 9H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}

function ZoomInIcon() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className="stroke-primary dark:stroke-primaryDark"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6">
        <path
          d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19.0004 19L14.6504 14.65"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M9 6V12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M6 9H12" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  )
}
